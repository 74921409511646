'use client';

import { useEffect } from 'react';

/**
 * This disables preview mode for the next request using the relevant cookie,
 * to avoid draft mode persisting after the preview page request.
 */
export default function Preview({ isDraftModeEnabled }: { isDraftModeEnabled: boolean }) {
  // Only run once
  useEffect(() => {
    if (isDraftModeEnabled) {
      fetch('/api/preview/disable', { method: 'POST' });
    }
  }, [isDraftModeEnabled]);

  return null;
}
