'use client';

import { useEffect, useRef } from 'react';
import { usePathname } from 'next/navigation';

export default function HubspotTracking() {
  const pathname = usePathname();

  const isFirstLoad = useRef(true);

  useEffect(() => {
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', pathname]);
    _hsq.push(['trackPageView']);

    if (isFirstLoad.current === true) {
      /**
       * Using the native URLSearchParams API instead of Next.js's useSearchParams as that requires
       * a suspense boundary and we don't need it to be reactive anyway as it's only used on first
       * load - https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout
       */
      const searchParams = new URLSearchParams(window.location.search);
      const emailAddress = searchParams.get('email');

      if (emailAddress) {
        _hsq.push([
          'identify',
          {
            email: emailAddress,
          },
        ]);
      }
      isFirstLoad.current = false;
    }
  }, [pathname]);

  return (
    // Using <script> as <Script> doesn't support type="text/plain", which means we have to be
    // careful that this script doesn't run multiple times. As this component is only used in
    // layout.tsx though and not page.tsx, it should be fine.
    <script
      type="text/plain"
      data-cookieconsent="marketing"
      id="hs-script-loader"
      async
      defer
      src="https://js-eu1.hs-scripts.com/24983590.js"
    />
  );
}
