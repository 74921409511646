'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import type { IStaticMethods } from 'preline/preline';
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

/**
 * This solution is adapted from the "Preline UI Javascript loader" solution:
 * https://preline.co/docs/frameworks-nextjs.html. There was some errors caused when using
 * `window.HSStaticMethods` so I've used `preline.HSStaticMethods` which refers is the same thing
 * but seems to be more reliable.
 */
export default function Preline() {
  const path = usePathname();

  useEffect(() => {
    const loadPreline = async () => {
      await import('preline/preline');

      window.HSStaticMethods.autoInit();
    };

    loadPreline();
  }, [path]);

  return null;
}
